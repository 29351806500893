import React, { useState } from 'react';

function App() {
  const [image, setImage] = useState(null);
  const [agentId, setAgentId] = useState('');
  const [videoAskUrl, setVideoAskUrl] = useState('');
  const [showContent, setShowContent] = useState(false);
  const [selectedWidget, setSelectedWidget] = useState('tixae');

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAgentIdChange = (event) => {
    setAgentId(event.target.value);
  };

  const handleVideoAskUrlChange = (event) => {
    setVideoAskUrl(event.target.value);
  };

  const cleanupWidgets = () => {
    const tixaeScript = document.querySelector('script[src*="vg_bundle.js"]');
    if (tixaeScript) {
      document.body.removeChild(tixaeScript);
    }

    const videoAskScript = document.querySelector('script[src*="videoask.com/embed/embed.js"]');
    if (videoAskScript) {
      document.body.removeChild(videoAskScript);
    }

    window.VG_CONFIG = undefined;
    window.VIDEOASK_EMBED_CONFIG = undefined;
  };

  const handleGeneratePage = () => {
    // Validation
    if (selectedWidget === 'tixae' && !agentId) {
      alert('Please enter the TIXAE Agent ID.');
      return;
    }
    if (selectedWidget === 'videoask' && !videoAskUrl) {
      alert('Please enter the VideoAsk URL.');
      return;
    }
    if (!image) {
      alert('Please upload an image.');
      return;
    }

    // Clean up any existing widgets
    cleanupWidgets();

    if (selectedWidget === 'tixae') {
      const agentScript = `
        (function() {
          window.VG_CONFIG = {
            ID: "${agentId}",
            region: 'na',
            render: 'popup',
            stylesheets: [
              "https://vg-bunny-cdn.b-cdn.net/vg_live_build/styles.css",
            ],
            autostart: false,
          };
          var VG_SCRIPT = document.createElement("script");
          VG_SCRIPT.src = "https://vg-bunny-cdn.b-cdn.net/vg_live_build/vg_bundle.js";
          VG_SCRIPT.defer = true;
          document.body.appendChild(VG_SCRIPT);
        })();
      `;
      const scriptElement = document.createElement("script");
      scriptElement.innerHTML = agentScript;
      document.body.appendChild(scriptElement);
    } else {
      const videoAskScript = `
        window.VIDEOASK_EMBED_CONFIG = {
          "kind": "widget",
          "url": "${videoAskUrl}",
          "options": {
            "widgetType": "VideoThumbnailExtraLarge",
            "text": "",
            "backgroundColor": "#7D00FE",
            "position": "bottom-right",
            "dismissible": false,
            "videoPosition": "center center"
          }
        };
      `;
      const configScript = document.createElement("script");
      configScript.innerHTML = videoAskScript;
      document.body.appendChild(configScript);

      const embedScript = document.createElement("script");
      embedScript.src = "https://www.videoask.com/embed/embed.js";
      embedScript.defer = true;
      document.body.appendChild(embedScript);
    }

    setShowContent(true);
  };

  return (
    <div 
      className="App" 
      style={{
        width: '100vw', 
        height: '100vh',
        backgroundImage: showContent && image ? `url(${image})` : 'none',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
        overflow: 'hidden',
        backgroundColor: 'transparent'
      }}
    >
      {/* Input Form */}
      {!showContent && (
        <div 
          style={{
            width: '300px',
            textAlign: 'center', 
            backgroundColor: 'rgba(255, 255, 255, 0.9)', 
            padding: '20px', 
            borderRadius: '8px',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
            margin: 'auto',
            marginTop: '10vh'
          }}
        >
          <h3>Select Widget Type</h3>
          <select 
            value={selectedWidget} 
            onChange={(e) => setSelectedWidget(e.target.value)}
            style={{ marginBottom: '10px', padding: '5px', width: '100%' }}
          >
            <option value="tixae">TIXAE Agent</option>
            <option value="videoask">VideoAsk</option>
          </select>

          {selectedWidget === 'tixae' ? (
            <>
              <h3>Please input the ID of the TIXAE Agent</h3>
              <input
                type="text"
                placeholder="Enter AI Agent ID"
                value={agentId}
                onChange={handleAgentIdChange}
                style={{ marginBottom: '10px', padding: '5px', width: '100%' }}
              />
            </>
          ) : (
            <>
              <h3>Please input the VideoAsk URL</h3>
              <input
                type="text"
                placeholder="Enter VideoAsk URL (e.g., https://www.videoask.com/f4olj5q1v)"
                value={videoAskUrl}
                onChange={handleVideoAskUrlChange}
                style={{ marginBottom: '10px', padding: '5px', width: '100%' }}
              />
            </>
          )}

          <h3>Upload the image to be shown</h3>
          <input 
            type="file" 
            accept="image/*" 
            onChange={handleImageUpload} 
            style={{ marginBottom: '10px', width: '100%' }} 
          />
          <button 
            onClick={handleGeneratePage} 
            style={{ padding: '10px 20px', width: '100%' }}
          >
            Generate
          </button>
        </div>
      )}

      {/* Widget Container */}
      {showContent && (
        <div 
          style={{
            position: 'fixed', 
            bottom: '20px', 
            left: '20px', 
            width: 'auto', 
            height: 'auto',
            backgroundColor: 'transparent'
          }} 
          id="VG_OVERLAY_CONTAINER"
        >
          {/* Placeholder for widget */}
        </div>
      )}
    </div>
  );
}

export default App;